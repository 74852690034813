:root {
  --gridUnit: 4px;
  --boardWidth: 688px;
  --boardHeight: 284px;
  --iconSize: 35px;
  --holeHeight: 66px;
  --holeWidth: 82px;
  --seedRadius: var(--gridUnit) * 1.5;
  --boardOrange: #ef8d3982;
  --boardGrey: #525354;
  --seedOrange: #ef8d39;
  --seedGrey: #525354;
  --activeGreen: #18af24;
  --grey1: #666;
  --grey2: #777;
  --grey3: #888;
  --grey4: #999;
  --grey5: #aaa;
  --grey6: #bbb;
  --grey7: #ccc;
  --grey8: #ddd;
  --grey9: #eee;
  --grey95: #efefef;
}

.blankIcon {
  width: var(--iconSize);
  height: var(--iconSize);
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  border-bottom: 4px solid black;
  width: calc(var(--gridUnit) * 65);
}

.board {
  display: flex;
  flex-direction: column;
  width: var(--boardWidth);
  height: var(--boardHeight);
  align-items: center;
  justify-content: center;
}
