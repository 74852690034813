.rear {
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-start;
}

.front {
  display: flex;
  z-index: 1;
}

.sideWrapper {
  display: flex;
  align-items: center;
}

.boardSide {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-content: center;
}
.boardSide0 {
  display: flex;
  flex-direction: column-reverse;
  width: fit-content;
}

.boardSide0 .front {
  flex-direction: row-reverse;
  align-self: flex-start;
  &.portrait {
    flex-direction: row;
  }
}

.boardSide0 .rear {
  flex-direction: row;
  align-self: flex-start;
}

.active {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-bottom: 3px;
  background-color: #18af24;
}
