.portrait {
  display: flex;
  flex-direction: row-reverse;
}

.sideSeparator {
  height: calc(2.5 * var(--gridUnit));
}

.portrait .sideSeparator {
  width: calc(2.5 * var(--gridUnit));
  height: unset;
}

.shrink {
  --boardHeight: calc(var(--boardHeight) * 0.7);
  --boardWidth: calc(var(--boardWidth) * 0.7);
}
