.hand {
  width: 80%;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 1;
  fill: #525354;
  stroke: #e0ba9a;
}

.rotate {
  top: 0px;
  stroke: #525354;
  fill: #e0ba9a;
  transform: rotate(180deg) translateY(50px);
  z-index: 4;
}
